






































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { DataOptions } from 'vuetify'
import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue'
import { FormMotivoDaMovimentacao, MotivoDaMovimentacao } from '@/models'
import DialogoDeEdicaoDeMotivoDeMovimentacao from '@/components/movimentacoes/DialogoDeEdicaoDeMotivoDeMovimentacao.vue';
import { FindMotivoDaMovimentacaoUseCase, DeleteMotivoDaMovimentacaoUseCase, FindLojaUseCase } from '@/usecases';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import axios, { CancelTokenSource } from 'axios'
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'

@Component({
	components: {
		DataTableDeCrud,
		DialogoDeEdicaoDeMotivoDeMovimentacao,
		SeletorDeLojasDoUsuario,
	},
})
export default class TelaDeMotivoDeMovimentacoes extends Vue {
	@Ref() dialogoDeEdicao!: DialogoDeEdicaoDeMotivoDeMovimentacao

	motivos: MotivoDaMovimentacao[] = []
	carregando = false
	totalRegistros = -1
	deleteMotivo = new DeleteMotivoDaMovimentacaoUseCase()
	findUseCase = new FindMotivoDaMovimentacaoUseCase()
	cancelToken: CancelTokenSource | null = null
	editando = true
	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}
	lojaId: string | undefined
	findLojaUseCase = new FindLojaUseCase()

	headers = [
		{ text: 'Nome', value: 'nome'},
		{ text: 'Utilizado em', value: 'displayUtilizadoEm'},
	]

	async created() {
		const idLojaUnica = await this.idLojaUnica()
		if(!idLojaUnica) return
		this.lojaId = idLojaUnica
		
		this.buscar()
	}

	async idLojaUnica() {
		const lojasIds = await this.findLojaUseCase.find()
		if(lojasIds.content.length === 1){
			return lojasIds.content[0].id
		}
		else { return null }
	}

	async buscar() {
		if (this.cancelToken) this.cancelToken.cancel()
		try{
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()

			const params = {
				page: this.paginacao.page - 1,
				lojaId: this.lojaId || undefined,
				size: this.paginacao.itemsPerPage,
				sort: 'nome',
			}

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			const page = await this.findUseCase.listaMotivosDeMovimentacoesByUser(params, axiosConfig)
			this.totalRegistros = page.totalElements
			this.motivos = page.content
		} catch(error) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	get motivosFormatados(){
		return this.motivos.map(motivo => ({
			...motivo,
			displayUtilizadoEm: motivo.utilizadoEm.join(', '),
		}))
	}

	atualizarMotivoDeMovimentacao(motivo: MotivoDaMovimentacao) {
		if (!motivo) return
		const indice = this.motivos.findIndex(
			motivoExistente => motivoExistente.id === motivo.id,
		)
		indice === -1
			? this.motivos = [ ...this.motivos, motivo ]
			: this.motivos.splice(indice, 1, motivo)
		++this.totalRegistros
	}

	criar () {
		this.editando = false
		const motivo = criarMotivoDeMovimentacao()
		this.dialogoDeEdicao.mostrar(motivo, true)
	}

	editar (indice: number ) {
		const motivo = this.motivos[indice]
		if (!this.motivos) return
		this.editando = true
		this.dialogoDeEdicao.mostrar(motivo, false)
	}

	async deletar(indice: number) {
		const motivo = this.motivos[indice]
		if (!motivo.id) return
		try {
			await this.deleteMotivo.delete(motivo.id)
			this.motivos = this.motivos.filter(({ id }) => id !== motivo.id)
			AlertModule.setSuccess(`${motivo.nome} excluido com sucesso`)
		} catch (error: any) {
			AlertModule.setError(error)
		}
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao(){
		this.buscar()
	}
}

function criarMotivoDeMovimentacao() {
	const motivo: FormMotivoDaMovimentacao = {
		id: '',
		nome: '',
		utilizadoEm: ['Sangria'],
		despesa: true,
		temObservacao: true,
		lojaId: '',
	}
	return motivo
}

