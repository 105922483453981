
































































































import { FormMotivoDaMovimentacao, MotivoDaMovimentacao } from '@/models'
import { Vue, Component, Ref } from 'vue-property-decorator'
import { obrigatorio } from '@/shareds/regras-de-form'
import { SaveMotivoDaMovimentacaoUseCase } from '@/usecases'
import { TipoDeOperacao } from '@/models/Recurso'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { montarOperacaoDeRecurso } from '@/shareds/permissoes-shareds'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'


@Component({
	components: {SeletorDeLojasDoUsuario},
})

export default class DialogoDeEdicaoDeMotivoDeMovimentacao extends Vue {
	@Ref() form!: HTMLFormElement
	@Ref() campoDeNome!: HTMLInputElement

	motivo: MotivoDaMovimentacao | null = null
	obrigatorio = obrigatorio
	mostra = false
	criandoMovimentacao = false
	salvando = false
	saveMotivoDeMovimentacaoUseCase = new SaveMotivoDaMovimentacaoUseCase()


	mostrar(motivo: FormMotivoDaMovimentacao, criandoNovoMotivo: boolean ) {
		this.motivo = JSON.parse(JSON.stringify(motivo))
		this.mostra = true
		this.criandoMovimentacao = criandoNovoMotivo

		setTimeout(() => {
			this.form.resetValidation()
			this.campoDeNome.focus()

		})
	}

	esconder() {
		this.motivo = null
		this.mostra = false
	}

	get podeAlterarMotivo() {
		return this.podeRealizarOperacao('alterar')
	}

	podeRealizarOperacao(operacao: TipoDeOperacao) {
		return !'motivosDaMovimentacao' || UserLoginStore.temAutorizacao(montarOperacaoDeRecurso(operacao, 'motivos-da-movimentacao'))
	}

	async salvar(){
		if (!this.motivo) return
		if (!this.form.validate()) return
		try {
			this.salvando = true
			const motivoSalvo = !this.motivo.id
				? await this.saveMotivoDeMovimentacaoUseCase.create(this.motivo)
				: await this.saveMotivoDeMovimentacaoUseCase.update(this.motivo)

			this.$emit('confirmado', motivoSalvo)
			this.mostra = false
			AlertModule.setSuccess('Movimentção salva com sucesso')
		}catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}
}
